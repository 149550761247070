import React, { useState, useEffect, useContext } from "react"
import { Link, useStaticQuery, graphql, StaticQuery } from "gatsby"

import Icons, { Icon } from "../Icons"
import Image from "../Image"
import Certifications from "./Certifications"
import ContactUs from "./ContactUs"
import Company from "./Company"
import Services from "./Services"

import SiteConfig from "../../context/SiteConfig"

const Footer = ({ loaction }) => {
  const { pathname } = location
  const { activeLanguage } = useContext(SiteConfig)

  const { strapiFooter } = useStaticQuery(graphql`
    {
      strapiFooter {
        footerCompany {
          items {
            en
            id
            is
          }
          link {
            to
            title {
              is
              en
            }
            externalLink
          }
        }
        footerContactUs {
          phone
          email
          id
          title {
            is
            en
          }
          location {
            externalLink
            to
            title {
              en
              is
            }
          }
        }
        footerCertifications {
          images {
            alt {
              en
              is
            }
            src {
              formats {
                thumbnail {
                  url
                  width
                }
                small {
                  url
                  width
                }
              }
              url
            }
            id
          }
        }
      }
    }
  `)

  const language =
    pathname.split("/")[1].length === 2
      ? pathname.split("/")[1].toLowerCase()
      : "is"

  const {
    footerContactUs,
    footerServices,
    footerCertifications,
    footerCompany,
  } = strapiFooter

  return (
    <footer className=" bg-neutrals-200 border-t-4 border-neutrals-300 ">
      <div className="container mx-auto px-4 py-8 lg:px-10 lg:grid lg:grid-cols-4">
        <div className="col-span-3 lg:flex  ">
          <ContactUs
            title={footerContactUs.title}
            phone={footerContactUs.phone}
            email={footerContactUs.email}
            location={footerContactUs.location}
            activeLanguage={activeLanguage}
          />
          <Company
            link={footerCompany.link}
            items={footerCompany.items}
            activeLanguage={activeLanguage}
          />
        </div>
        <Certifications certificationsIcons={footerCertifications.images} />
      </div>
    </footer>
  )
}

export default Footer
