import React, { useState, useEffect } from "react"
import Portal from "../Portal"


const formats = ["thumbnail", "small", "medium", "large"]

const getImageUrlByMaxWith = (image, maxWidth) => {
  let tempImage

  try {
    for (const format of formats) {
      if (image.formats[format]?.width >= maxWidth) {
        tempImage = image.formats[format].url

        break
      }
    }
  } catch {}
  if (!tempImage) return image.url

  return tempImage
}

const Img = ({
  src,
  alt,
  fullPath,
  banner,
  className,
  style,
  onLoad,
  imageRef,
  maxWidth,
}) => {

  const [img, setImgState] = useState(null)

  const setImg = () => {
    let imageUrl

    let serverSrc = `${process.env.GATSBY_API_URL}`
    if (!imageUrl) imageUrl = getImageUrlByMaxWith(src, maxWidth)

    setImgState(`${serverSrc}${imageUrl}`)
  }

  useEffect(() => {
    setImg()
  }, [])

  const onZoom = () => {
    setIsZoomed(true)
  }

  const onQuitZoom = () => {
    setIsZoomed(false)
  }


  if (!img) return <div />

  return (
    <>
      <img
        onLoad={onLoad}
        style={style}
        className={className}
        ref={imageRef}
        src={img}
        alt={alt}
      />

    </>
  )
}

export default Img

