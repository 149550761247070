import React, { useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { motion } from "framer-motion"

const menuItemsVariants = {}

const navItems = ({
  navItem,
  activePage,
  xl,
  closeMobileMenu,
  last,
  activeLang,
}) => {
  const onChangePage = () => {
    setTimeout(closeMobileMenu, 600)
  }

  useEffect(() => {}, [xl])

  return (
    <li className={`${last ? "" : "mb-1 lg:mb-0 lg:mr-4"}`}>
      <Link
        to={`${activeLang === "is" ? "" : `/${activeLang}`}${navItem.to}`}
        className={`relative text-3xl whitespace-nowrap lg:text-xl hover:text-neutrals-500
          ${activePage ? "text-neutrals-400" : "text-white"}
        `}
        onClick={onChangePage}
        key={navItem.title}
      >
        {navItem.title[activeLang]}
        {activePage && (
          <div
            className=" absolute inset-y-0 flex items-center xl:hidden"
            style={{ transform: "translate3d(-30px , 0 , 0)" }}
          >
            <div className="w-4 h-1 bg-neutrals-300"></div>
          </div>
        )}
      </Link>
    </li>
  )
}

export default navItems
