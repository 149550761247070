import React, { useState, useEffect, useContext } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import SiteConfig from "../../context/SiteConfig"

import Icons, { Icon } from "../Icons"
import { AnimatePresence, motion } from "framer-motion"

interface Props {
  location: Location
}

const variants = {
  hidden: {
    opacity: 0,
    y: -80,
  },
  shown: {
    opacity: 1,
    y: 0,
  },
}

interface Language {
  acronym: String
  name: String
}

const LaunguageSelect = ({ location }: Props) => {
  const { pathname } = location
  const { lg, setActiveLanguages } = useContext(SiteConfig)
  const [isChoosingLanguage, setIsChoosingLanguage] = useState(false)
  const [currentPath, setCurrentPath] = useState("")
  const [activeLang, setActiveLang] = useState("")

  const { allStrapiLanguages } = useStaticQuery(graphql`
    {
      allStrapiLanguages {
        languages: nodes {
          acronym
          name
        }
      }
    }
  `)

  const { languages }: [Language] = allStrapiLanguages

  const getRidOfLangPath = pathName =>
    pathName.split("/")[1].length === 2 ? pathname.slice(3) : pathname

  const getActiveLangOfPath = pathName =>
    pathName.split("/")[1].length === 2
      ? pathName.split("/")[1].toLocaleLowerCase()
      : "is"

  const getLanguageTo = acronym =>
    acronym.toLocaleUpperCase() === "IS"
      ? `${currentPath}`
      : `/${acronym}${currentPath}`

  const getNotActiveLanguage = () =>
    languages.find(language => language.acronym != activeLang.acronym)

  useEffect(() => {
    setCurrentPath(getRidOfLangPath(pathname))
    const languagePath = getActiveLangOfPath(pathname)
    setActiveLang(languages.find(language => language.acronym === languagePath))
  }, [pathname])

  useEffect(() => {
    if (languages.length < 3) return
    setIsChoosingLanguage(false)
    setActiveLanguages(activeLang.acronym)
  }, [activeLang])

  useEffect(() => {
    if (languages.length < 3) return
    setIsChoosingLanguage(false)
  }, [pathname])

  useEffect(() => {
    setActiveLanguages(getActiveLangOfPath(pathname))
  }, [])

  if (languages.length <= 1) return <div />

  if (!activeLang) return <div />

  if (languages.length === 2) {
    return (
      <div className="xl:ml-6 xl:pl-6 flex xl:border-l-2 border-neutrals-800 my-4">
        <div
          className="m-auto"
          onClick={() => setActiveLanguages(getNotActiveLanguage().acronym)}
        >
          <Link
            className="text-lg font-semibold text-white"
            to={getLanguageTo(
              getNotActiveLanguage().acronym.toLocaleLowerCase()
            )}
          >
            {getNotActiveLanguage().name}
          </Link>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="hidden xl:flex w-full justify-between my-4 lg:pl-4 xl:ml-6 xl:border-l-2 border-neutrals-800">
        <div
          className="flex items-center cursor-pointer z-30 bg-neutrals-900"
          onClick={() => {
            setIsChoosingLanguage(!isChoosingLanguage)
          }}
        >
          <h5 className="text-neutrals-300 text-2xl mr-4 select-none">
            {activeLang.acronym.toLocaleUpperCase()}
          </h5>

          <Icons
            className="w-4 h-4 text-neutrals-300 fill-current"
            icon={Icon.arrowDown}
          />
        </div>
        <AnimatePresence>
          {isChoosingLanguage && (
            <motion.div
              variants={variants}
              initial="hidden"
              animate="shown"
              exit="hidden"
              className="absolute z-20"
              style={{ top: "90px" }}
            >
              <ul className="bg-neutrals-900 rounded-lg py-4 px-8 -ml-6 ">
                {languages
                  .filter(
                    (language, index) => language.acronym !== activeLang.acronym
                  )
                  .map(language => (
                    <li
                      key={language.acronym.toLocaleUpperCase()}
                      className="text-xl xxl:text-2xl text-neutrals-400  "
                    >
                      <Link
                        to={getLanguageTo(language.acronym.toLocaleLowerCase())}
                        className="select-none"
                      >
                        {language.acronym.toLocaleUpperCase()}
                      </Link>
                    </li>
                  ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="xl:hidden ">
        {languages.map(language => (
          <Link
            className={`text-neutrals-400 text-xl mr-6 select-none ${
              activeLang.acronym === language.acronym
                ? " border-b-2 border-neutrals-400 pb-1"
                : ""
            }`}
            to={getLanguageTo(language.acronym.toLocaleLowerCase())}
            key={language.acronym}
          >
            {language.name}
          </Link>
        ))}
      </div>
    </>
  )
}

export default LaunguageSelect
