import React, { useState, useRef, useEffect, useContext } from "react"
import { Link, useStaticQuery, graphql, StaticQuery } from "gatsby"

import SiteConfig from "../../context/SiteConfig"

import SelectLanguage from "../SelectLanguage"

import Nav from "../Nav"
import Image from "../Image"

const Header = ({ pathName, onOpenMenu, location }) => {
  const { setHeaderHeight, lg, xl, activeLanguage } = useContext(SiteConfig)

  const [isLogoLoading, setIsLogoLoading] = useState(true)

  const HeaderRef = useRef(null)

  const { strapiHeader } = useStaticQuery(graphql`
    {
      strapiHeader {
        logo {
          alt {
            is
            en
          }
          src {
            url
            width
          }
        }
      }
    }
  `)
  const getActiveLangOfPath = pathName =>
    pathName.split("/")[1].length === 2
      ? pathName.split("/")[1].toLocaleLowerCase()
      : "is"

  useEffect(() => {
    if (!HeaderRef.current) return
    setHeaderHeight(HeaderRef.current.clientHeight)
  }, [, isLogoLoading])

  return (
    <header className=" sticky top-0 left-0 w-full z-30">
      <div
        className=" w-full flex justify-between items-center px-4 py-2 xl:py-0 xl:px-8 bg-neutrals-900 z-30 shadow-xl"
        ref={HeaderRef}
      >
        <Link className=" " to="/">
          <Image
            onLoad={() => setIsLogoLoading(false)}
            src={strapiHeader.logo.src}
            alt={strapiHeader.logo.alt[getActiveLangOfPath(pathName)]}
            className=" w-56 py-2 xl:w-64"
          />
        </Link>

        <div className="hidden xl:flex">
          <Nav
            pathName={pathName}
            availableJobsLanguages={strapiHeader.availableJobsLanguages}
          />
          <SelectLanguage location={location} />
        </div>

        <div
          className="self-center  text-neutrals-400 fill-current xl:hidden"
          onClick={onOpenMenu}
        >
          <svg
            className="w-8 h-8 "
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </div>
      </div>
    </header>
  )
}

export default Header
