import React from "react"
import Obfuscate from "react-obfuscate"

import Icons, { Icon } from "../Icons"

const ContactUs = ({ title, phone, email, location, activeLanguage }) => {
  return (
    <div className="pb-6 lg:mb-0 lg:mr-14 xl:mr-24">
      <h3 className="text-xl lg:text-2xl text-neutrals-700 ">
        {title[activeLanguage]}
      </h3>

      <ul className="text-lg lg:text-xl text-neutrals-800 fill-current m-1 pl-4 border-l-4 border-primary-400">
        <li className="flex  my-4">
          <Icons
            className="w-6 h-6 mr-6 text-neutrals-600 fill-current"
            icon={Icon.phone}
          />
          <Obfuscate tel={activeLanguage == "is" ? phone : `+354 ${phone}`} />
        </li>
        <li className="flex my-4">
          <Icons
            className="w-6 h-6 mr-6 text-neutrals-600 fill-current"
            icon={Icon.mail}
          />
          <Obfuscate email={email} />
        </li>
        <li className="flex">
          <Icons
            className="w-6 h-6 mr-6 text-neutrals-600 fill-current"
            icon={Icon.location}
          />
          <a href={location.to} target="_blank" rel="noreferrer">
            {location.title[activeLanguage]}
          </a>
        </li>
      </ul>
    </div>
  )
}

export default ContactUs
