import React, { useState, useEffect, useContext } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import SettingsContext from "../../context/SiteConfig"

import NavItems, { NavItem } from "./navItems"

const Nav = ({ pathName, closeMobileMenu }) => {
  const { xl } = useContext(SettingsContext)
  const [activePage, setActivePage] = useState("/")
  const [activeLang, setActiveLang] = useState(null)
  const [isAvailableJobs, setIsAvailableJobs] = useState(false)

  const {
    strapiHeader: { nav, availableJobsLanguages },
  } = useStaticQuery(graphql`
    {
      strapiHeader {
        nav {
          title {
            is
            en
          }
          id
          to
        }
        availableJobsLanguages
      }
    }
  `)

  // Change this later, import the languages on go over them
  const getCurrentPagePathName = () => {
    const url = pathName.split("/")
    if (url[1].length > 3 || url[1] === "") {
      setActiveLang("is")
      return `/${url[1]}` // ["", "activePage" ]
    } else {
      setActiveLang(url[1])
      return `/${url[2]}` // ["", "en", "activePage"]
    }
  }

  useEffect(() => {
    setActivePage(getCurrentPagePathName())
  }, [pathName])

  useEffect(() => {
    if (availableJobsLanguages.includes(activeLang)) {
      setIsAvailableJobs(true)
    } else {
      if (isAvailableJobs) {
        setIsAvailableJobs(false)
      }
    }
  }, [, activeLang])

  if (activeLang === null) return <div />
  return (
    <ul className="py-2 lg:py-6 mx-6  xl:flex xl:my-1 xl:mx-0">
      {nav
        .filter(item => {
          if (item.to == "/available_jobs") {
            if (availableJobsLanguages.includes(activeLang)) {
              return item
            }
          } else {
            return item
          }
        })
        .map((item, index) => (
          <NavItems
            key={`${index}${item.to}`}
            navItem={item}
            activePage={activePage === `${item.to}`}
            xl={xl}
            closeMobileMenu={closeMobileMenu}
            last={
              index ===
              nav.filter(item => {
                if (item.to == "/available_jobs") {
                  if (availableJobsLanguages.includes(activeLang)) {
                    return item
                  }
                } else {
                  return item
                }
              }).length -
                1
            }
            activeLang={activeLang}
          />
        ))}
    </ul>
  )
}

export default Nav
