import React, { useContext } from "react"

import Image from "../Image"

import SiteConfig from "../../context/SiteConfig"

const Certifications = ({ certificationsIcons }) => {
  const { activeLanguage } = useContext(SiteConfig)

  return (
    <div className="flex justify-center mt-auto lg:justify-end">
      {certificationsIcons.map(icon => {
        return (
          <div key={icon.src.url} className="ml-2">
            <Image
              className=" max-h-28 "
              style={{ filter: "grayscale(30%)" }}
              src={icon.src}
              alt={icon.alt[activeLanguage]}
              maxWidth={400}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Certifications
