import React from "react"

const Company = ({ link, items, activeLanguage }) => {
  return (
    <div className="pb-6 lg:pb-0">
      <h3 className="text-xl lg:text-2xl text-neutrals-700 ">
        {link.title[activeLanguage]}
      </h3>

      <ul className="text-xl text-neutrals-800 fill-current ml-1 pl-4 lg:gird lg:grid-cols-1 border-l-4 border-primary-400">
        {items.map((item, index) => (
          <li key={index} className="my-4">
            <h4 className="text-lg lg:text-xl">{item[activeLanguage]}</h4>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Company
