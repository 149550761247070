import React from "react"
import { SiteConfigProvidor } from "./src/context/SiteConfig"
import "./src/styles/global.css"
import "mapbox-gl/dist/mapbox-gl.css"
import "react-alice-carousel/lib/alice-carousel.css"

import Layout from "./src/components/Layout"

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Layout {...props}>{element}</Layout>
      <div id="portal" className="w-full h-full"></div>
    </>
  )
}

export const wrapRootElement = ({ element }) => (
  <SiteConfigProvidor>{element}</SiteConfigProvidor>
)
