import React, { useEffect, useState } from "react"

import { motion, AnimatePresence } from "framer-motion"

import Icons, { Icon } from "../Icons"
import SelectLanguage from "../SelectLanguage"
import Logo from "../Image/Logo"
import Nav from "../Nav"

const menuVariants = {
  open: { opacity: 0.5, x: "100%" },
  center: { opacity: 1, x: 0 },
  close: { opacity: 0.5, x: "100%" },
}

const MobileMenu = ({
  isMenuOpen,
  onCloseMenu,
  pathName,
  location,
  navItems,
}) => {
  const [activePage, setActivePage] = useState("/")

  return (
    <AnimatePresence>
      {isMenuOpen && (
        <motion.div
          variants={menuVariants}
          initial="open"
          animate="center"
          exit="close"
          transition={{
            duration: 0.2,
            damping: 0,
          }}
          className="fixed top-0 left-0 w-full max-h-screen h-full z-40 bg-neutrals-900 overflow-hidden"
          onAnimationComplete={() => {}}
        >
          <div className=" my-8 mx-4">
            <div className="w-full max-w-xs mb-4 h-28 m-auto">
              <Logo />
            </div>
            <Nav
              pathName={pathName}
              closeMobileMenu={onCloseMenu}
              navItems={navItems}
              mobile={true}
            />
            <div className="w-full py-10  flex justify-between items-center px-6 mb-8 items-center">
              <SelectLanguage mobile location={location} />
              <div className="" onClick={onCloseMenu}>
                <Icons
                  className="w-6 h6 text-neutrals-100 fill-current"
                  icon={Icon.x}
                />
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default MobileMenu
