/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useRef, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../Header"
import Footer from "../Footer"
import MobileMenu from "../MobileMenu"
import SiteConfig from "../../context/SiteConfig"

interface Props {
  children: any
  indexPage: Boolean
  location: Location
}

const Layout = ({ children, indexPage, location }: Props) => {
  const [menuAnimationEnded, setMenuAnimationEnded] = useState(false)

  const { setScrollTo, headerHeight } = useContext(SiteConfig) || {
    setScrollTo: () => {},
  }

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  //const siteTitle = useSiteTitleData()

  const onCloseMenu = () => {
    setIsMobileMenuOpen(false)
    setMenuAnimationEnded(false)
  }

  const onOpenMenu = () => {
    setIsMobileMenuOpen(true)
    setTimeout(() => setMenuAnimationEnded(true), 500)
  }

  useEffect(() => {
    try {
      if (!location.state.scrollTo) return

      setScrollTo(location.state.scrollTo)
    } catch {}
  }, [location])

  if (typeof window === `undefined`) {
    return <></>
  }

  return (
    <div className="relative">
      <Header
        location={location}
        //siteTitle={siteTitle}
        pathName={location && location.pathname}
        onOpenMenu={onOpenMenu}
      />
      <MobileMenu
        isMenuOpen={isMobileMenuOpen}
        onCloseMenu={onCloseMenu}
        pathName={location && location.pathname}
        location={location}
      />
      <main
        className={`relative font-sans bg-neutrals-200 overflow-hidden h-full ${
          isMobileMenuOpen && menuAnimationEnded ? "hidden" : ""
        }`}
        style={{ minHeight: `calc(100vh - ${headerHeight}px)` }}
      >
        {children}
      </main>
      <Footer location={location} />
    </div>
  )
}

export const useSiteTitleData = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return site.siteMetadata.title
}

export default Layout
