import React, { useState, useEffect } from "react"

const SiteConfigContext = React.createContext(null)

const SiteConfigProvidor = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState(0)
  const [scrollTo, setScrollTo] = useState(null)
  const [screenSizeType, setScreenSizeType] = useState({
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false
  })

  const [activeLanguage, setActiveLanguages] = useState("is")

  const getAndSetScreenSize = () => {
    const clientHeight = document.querySelector("body").clientWidth
    const tempScreenSizeType = {
      sm: true,
      md: false,
      lg: false,
      xl: false,
      xxl: false
    }

    if (Number(innerWidth) - 1 > 768) {
      tempScreenSizeType.md = true
    }

    if (Number(innerWidth) - 1 > 1024) {
      tempScreenSizeType.lg = true
    }

    if (Number(innerWidth) - 1 > 1280) {
      tempScreenSizeType.xl = true
    }
    if (Number(innerWidth) - 1 > 2000) {
      tempScreenSizeType.xxl = true
    }

    setScreenSizeType(tempScreenSizeType)
  }

  useEffect(() => {
    getAndSetScreenSize()

    window.addEventListener("resize", getAndSetScreenSize)
  }, [])

  return (
    <SiteConfigContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        sm: screenSizeType.sm,
        md: screenSizeType.md,
        lg: screenSizeType.lg,
        xl: screenSizeType.xl,
        xxl: screenSizeType.xxl,
        scrollTo: scrollTo,
        setScrollTo: setScrollTo,
        activeLanguage: activeLanguage,
        setActiveLanguages: setActiveLanguages
      }}
    >
      {children}
    </SiteConfigContext.Provider>
  )
}

export default SiteConfigContext

export { SiteConfigProvidor }
